import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import useStore from '../../data/store';
import t from '../../t';
import { HelpableBadge } from '../settings/settings';
import GiveBonus from '../asks/_give_bonus';

const Hubs = () => {
  const { userStore, hubStore } = useStore();

  useEffect(() => {
    hubStore.fetch();
  }, []);

  return (
    <>
      <div>
        {hubStore.records.length} Hubs on the Platform: Active:{' '}
        {hubStore.records.filter(record => record.isActive).length} | Draft:{' '}
        {hubStore.records.filter(record => !record.isActive).length}
      </div>
      <Card className="rounded-lg overflow-hidden">
        <CardBody style={{ background: '#f3f3f3' }}>
          {map(hubStore.records, record => (
            <Card key={record.id}>
              <CardHeader className="d-flex">
                <div className="ml-3 mr-3">
                  <HelpableBadge helpable={record} />
                </div>
                <div>
                  <a target="_blank" href={record.url} rel="noreferrer">
                    {record.title}
                  </a>
                </div>
              </CardHeader>

              <CardBody>
                <div>
                  Balance: {record.wallet.balance}
                  {userStore.currentUser && userStore.currentUser.isSystem && (
                    <GiveBonus
                      fromWalletId={userStore.currentUser.systemWalletId}
                      maxLimit={10000}
                      toWalletId={record.wallet.id}
                      defaultMessage="Gift by Ecosystem"
                      btnTitle="Gift Tokens"
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          ))}
        </CardBody>
      </Card>
    </>
  );
};

export default observer(Hubs);
