import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import {
  Button,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavbarBrand,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { Initial } from 'react-initial';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  faBars,
  faCalendarPlus,
  faGlobe,
  faLifeRing,
  faNetworkWired,
  faQuestion,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { faTv } from '@fortawesome/free-solid-svg-icons/faTv';
import router from 'react-router-dom/es/Router';
import Avatar from 'react-avatar';
import { toast } from 'react-toastify';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import ProjectSelector from '../modules/projects/selector';
import useStore from '../../data/store';
import Wallets from '../shared/_wallets';
import BasicForm from '../helpables/basic_form';
import Logo from './_logo.js';
import t from '../../t';
import NavIconLink from './header_nav_icon';
import HintedLabel from '../shared/_hinted_label';
import { redirectToAuth } from '../../routes/AuthenticatedRoute';
import VideoHelp from '../shared/_videoHelp';
import AccountForm from '../hubs/hub_form';
import AuthModal from '../shared/_auth_modal';

export const creatableProject = {
  name: 'projects',
  component: BasicForm,
  modalTitle: 'Add new Project',
  size: 'md',
};

const creatableAccount = {
  name: 'account',
  component: AccountForm,
  modalTitle: t(`onboard.hub.labels.button`),
  size: 'md',
};

const _header = ({ forcePublic = false }) => {
  const {
    projectStore,
    appStore,
    impersonateUserStore,
    userStore,
  } = useStore();
  const [showActions, setShowActions] = useState(false);
  const [auth, setAuth] = useState(userStore.authMode === 'verify');
  const history = useHistory();

  const { currentUser } = userStore;
  const { account } = projectStore;

  const isPublic =
    forcePublic || (isEmpty(currentUser) && !projectStore.hasActiveProject);

  const signOut = () => {
    if (currentUser.signOut()) {
      toast.success('Sign out successful.');
      history.push('/');
    }
  };

  const handleFeedBack = () => {
    const howuOverlay = document.getElementsByClassName('howu-overlay')[0];
    const howukuContainer = document.getElementsByClassName(
      'howuku-container'
    )[0];

    if (howukuContainer && howuOverlay) {
      howuOverlay.style.display = 'block';
      howukuContainer.style.display = 'block';
    }
  };

  const userNav = () => {
    if (userStore.userSignedIn) {
      return (
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle
            className="d-flex align-items-center"
            nav
            caret={false}
          >
            <Avatar
              name={currentUser.fullName}
              size="36"
              color="#c662e0"
              round
              style={{ borderRadius: '100%' }}
            />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem disabled>
              <div className="text-black font-weight-bolder">
                {currentUser.fullName}
              </div>
            </DropdownItem>
            <DropdownItem disabled className="tour-user-wallet">
              <Wallets walletable={currentUser} />
            </DropdownItem>
            <DropdownItem
              tag={Link}
              to="/user"
              className="tour-user-account-link"
            >
              {t('header.manage_user_account')}
            </DropdownItem>
            <DropdownItem divider />
            {!isEmpty(account) && account.canManage && (
              <>
                <DropdownItem tag={Link} to="/settings">
                  {t('header.manage_hub')}
                </DropdownItem>
              </>
            )}
            {currentUser.isSystem && (
              <>
                <DropdownItem
                  tag={Link}
                  to="/platform_admin"
                  className="font-weight-bold text-danger"
                >
                  {t('header.manage_ecosystem')}
                </DropdownItem>
                <DropdownItem divider />
              </>
            )}
            {currentUser.impersonating && (
              <>
                <DropdownItem
                  onClick={() => impersonateUserStore.stopImpersonate()}
                  className="tour-user-account-link"
                >
                  <FontAwesomeIcon icon={faUserSecret} />{' '}
                  {t('header.stop_impersonating')}
                </DropdownItem>
              </>
            )}

            <DropdownItem onClick={signOut}>{t('header.signout')}</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
    return (
      <div className="project-actions">
        <NavIconLink
          link="#"
          className="sign-in"
          icon="sign-in-alt"
          onClick={() => {
            userStore.update({ showAuthModal: true });
          }}
          title={t(`auth.authenticate.title`)}
        />
      </div>
    );
  };

  const startHubNav = () => (
    <div className="project-actions">
      <NavIconLink
        icon="plus"
        link={userStore.userSignedIn ? '#' : '/start_hub'}
        onClick={() => {
          if (userStore.userSignedIn)
            return appStore.initiateCreatable(creatableAccount);
        }}
        title={t(`header.create_hub`)}
        id="add-hub-btn"
      />
    </div>
  );

  const joinHubNav = () => (
    <div className="project-actions">
      <NavIconLink
        icon="plus"
        link={userStore.userSignedIn ? '#' : '/join_hub'}
        title={t(`account.join.public`)}
        id="join-hub-btn"
      />
    </div>
  );

  const discoverButton = () => (
    <div className="">
      <Button
        color="c662e0 text-light "
        title="Discover hubs to join"
        className="header-button"
        onClick={() => {
          history.push('/discover');
        }}
      >
        <FontAwesomeIcon icon={faGlobe} />
      </Button>
    </div>
  );

  const inHubCreateProjectNav = () => (
    <div className="project-actions">
      {projectStore.account.status === 'active' &&
        currentUser.confirmedProjects > 0 &&
        (projectStore.account.hasAccess ||
          projectStore.account.hasAccessPublic) && (
          <NavIconLink
            className="tour-activities-link"
            icon="plus"
            link="#"
            title={t(`header.add_project`)}
            id="add-project-btn"
            onClick={() => {
              appStore.initiateCreatable(creatableProject);
            }}
            disabled={
              projectStore.hasActiveProject &&
              !projectStore.activeProject.persisted
            }
          />
        )}
    </div>
  );

  const helpNav = () => (
    <UncontrolledDropdown nav inNavbar className="mx-2">
      <DropdownToggle className="d-flex align-items-center" nav caret={false}>
        <Button
          color="c662e0 text-light "
          title="Get help"
          className="header-button"
        >
          <FontAwesomeIcon icon={faQuestion} />
        </Button>
      </DropdownToggle>
      <DropdownMenu right className="get-help-bg">
        <DropdownItem disabled>
          <div>{t(`header.videos`)}</div>
        </DropdownItem>
        <DropdownItem>
          <HintedLabel
            className="small"
            helpTextKey="what_is_helpbuild"
            video
          />
        </DropdownItem>
        <DropdownItem>
          <HintedLabel className="small" helpTextKey="create_project" video />
        </DropdownItem>
        <DropdownItem>
          <HintedLabel className="small" helpTextKey="create_video" video />
        </DropdownItem>
        <DropdownItem divider />

        <DropdownItem disabled>
          <div>{t(`header.get_help`)}</div>
        </DropdownItem>
        <DropdownItem
          data-cal-namespace=""
          data-cal-link="helpbuild/webinar"
          data-cal-config='{"layout":"month_view"}'
        >
          <div className="text-black">
            <FontAwesomeIcon className="mr-2" icon={faTv} />
            {t(`header.book_webinar`)}
          </div>
        </DropdownItem>
        <DropdownItem
          data-cal-namespace=""
          data-cal-link="helpbuild/help"
          data-cal-config='{"layout":"month_view"}'
        >
          <div>
            <FontAwesomeIcon className="mr-2" icon={faCalendarPlus} />
            {t(`header.book_call`)}
          </div>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={handleFeedBack}>
          <div className="text-info">
            <FontAwesomeIcon className="mr-2" icon={faLifeRing} />
            {t(`header.leave_feedback`)}
          </div>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
  const createActions = () => (
    <UncontrolledDropdown nav inNavbar className="mx-2">
      <DropdownToggle className="d-flex align-items-center" nav caret={false}>
        <Button
          color="c662e0 text-light "
          title="Create a hub or project"
          className="header-button"
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </DropdownToggle>
      <DropdownMenu right className="get-help-bg">
        {projectStore.account.status === 'active' &&
          currentUser.confirmedProjects > 0 &&
          (projectStore.account.hasAccess ||
            projectStore.account.hasAccessPublic) && (
            <DropdownItem
              id="add-project-btn"
              onClick={() => {
                appStore.initiateCreatable(creatableProject);
              }}
              disabled={
                projectStore.hasActiveProject &&
                !projectStore.activeProject.persisted
              }
            >
              {t(`header.add_project`)}
            </DropdownItem>
          )}

        <DropdownItem
          onClick={() => {
            userStore.userSignedIn
              ? appStore.initiateCreatable(creatableAccount)
              : history.push('/start_hub');
            return appStore.initiateCreatable(creatableAccount);
          }}
          id="add-hub-btn"
        >
          {t(`header.create_hub`)}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  return (
    <>
      <header className="sticky-header">
        <Container>
          <Row>
            <NavbarBrand>
              <Logo />
            </NavbarBrand>
            {discoverButton()}
            {(projectStore.account.status === 'active' ||
              (!isPublic && !isEmpty(projectStore.activeProject))) && (
              <div className="project-selector-and-actions">
                {userStore.userSignedIn && (
                  <>
                    <div className="d-flex mx-2">
                      <Button
                        color="c662e0 text-light "
                        title="Go to your hub"
                        className="tour-hub-link header-button"
                        onClick={() => {
                          history.push(
                            account.myProjectHelper?.status === 'confirmed'
                              ? '/hub/dashboard'
                              : '/'
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faNetworkWired} />
                      </Button>
                    </div>
                    <ProjectSelector
                      showActions={showActions}
                      toggleActions={() => setShowActions(false)}
                    />
                    <Button
                      onClick={() => setShowActions(!showActions)}
                      className="action-toggler"
                    >
                      <FontAwesomeIcon icon={faBars} />
                    </Button>
                  </>
                )}
              </div>
            )}
            <Nav className="flex-row justify-content-end tour-user-bits" navbar>
              {!userStore.userSignedIn && (
                <>
                  {joinHubNav()}
                  {startHubNav()}
                </>
              )}
              {userStore.userSignedIn && (
                <>
                  {createActions()}
                  {helpNav()}
                </>
              )}
              {userNav()}
            </Nav>
          </Row>
        </Container>
      </header>
      {appStore.showVideoHelp && <VideoHelp />}
      <AuthModal mode="signin" />
    </>
  );
};

_header.propTypes = {
  forcePublic: PropTypes.bool,
};

export default observer(_header);
