import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { Alert, Button } from 'reactstrap';
import { isEmpty, isEqual } from 'lodash';
import { Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import useStore from '../../data/store';
import Player from '../modules/player';
import WrappedHTML from '../shared/_wrapped_html';
import HintedLabel from '../shared/_hinted_label';
import Projects from './projects';
import Spacer from '../layouts/spacer';
import t from '../../t';

const HubDashboard = ({ match }) => {
  const { projectStore } = useStore();
  const { account } = projectStore;

  const history = useHistory();

  useEffect(() => {
    if (isEmpty(account)) projectStore.fetchCurrentAccount();
  }, []);

  if (isEqual(account?.status, 'pending')) {
    if (!account.canManage) return <Redirect to="/hub404" />; // Safe Check, should already be handled by backend

    return <Redirect to="/settings" />;
  }

  function ctaButtons(showHelp = false, className = 'justify-content-center') {
    if (account.hasAccess) return;
    return (
      <div className={`d-flex flex-wrap ${className}`}>
        {!account.hasAccessPrivate && (
          <>
            <Button
              color="primary"
              size="xl"
              onClick={() =>
                history.push(
                  account.access === 'protected'
                    ? '/check-access-code'
                    : '/join_hub'
                )
              }
            >
              {t(`account.join.${account.access}`)}
            </Button>
            {showHelp && (
              <HintedLabel
                className="medium"
                helpTextKey="what_is_helpbuild"
                button
                video
              />
            )}
          </>
        )}
        {account.hasAccessPrivate && (
          <Alert color="danger" className="text-center">
            {t('account.join.private')}
          </Alert>
        )}
      </div>
    );
  }

  function sectionTitle(title, id = '') {
    return (
      <div id={id}>
        <hr />
        <h3>{title}</h3>
        <hr />
      </div>
    );
  }

  return (
    <>
      <Spacer space={3} />
      <div className="hub-landing-page">
        <div className="cta-hero d-flex w-100">
          <div className="p-1 w-50 pl-5">
            <h4>👋🏽 {t('account.welcome.title', { hub: account.title })}</h4>
            <h1>{account.title} Hub</h1>
            <Spacer space={1} />
            <h2 className="font-weight-light">{account.tagline}</h2>
            <Spacer space={3} />
            {ctaButtons(true, 'justify-content-start')}
          </div>
          <div className="w-50">
            <div className="border-left px-5">
              <Player
                url={account.videoUrl}
                controls="playOnly"
                muted={false}
              />
            </div>
          </div>
        </div>
        <Spacer space={1} />
        <div className="below-the-fold border-top pl-5">
          <div className="hub-nav d-flex justify-content-start pt-5">
            <a href="#summary" >
              Summary
            </a>
            <a href="#projects" >
              Projects
            </a>
          </div>
          <Spacer space={3} />
          <div className="text-left" id="summary">
            <WrappedHTML
              content={account.description}
              className="mt-1 hub-description"
            />
          </div>
        </div>
        {!account.hasAccessPrivate && (
          <>
            <Spacer space={3} />
            {sectionTitle(t('account.projects.title'), 'projects')}
            <Projects account={account} />
          </>
        )}
      </div>
    </>
  );
};
HubDashboard.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};
export default observer(HubDashboard);
